
import {Component, Mixins, Ref, Vue} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {VForm} from "@/types";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import XmlController from "@/plugins/uyap-plugin/takip_ac/XmlController";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {FileEntity} from "@/entity/FileEntity";
import TarafInterface from "@/plugins/uyap-plugin/takip_ac/parser/TarafInterface";
import {ITask, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTarget} from "@/plugins/uyap-plugin/enum/TaskTarget";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipHazirlaEvrakEkleForm from "@/components/eicrapro/TakipHazirlaEvrakEkleForm.vue";
import TelefonNumarasi from "@/components/inputs/TelefonNumarasi.vue";
import EPosta from "@/components/inputs/EPosta.vue";
import {AvukatYetkiliKurumlarItem} from "@/plugins/uyap-plugin/uyap/AvukatYetkiliKurumlar";
import DayanakList from "@/components/eicrapro/DayanakList.vue";
import FormButton from "@/components/dialogs/FormButton.vue";
import MahiyetSecForm from "@/components/eicrapro/MahiyetSecForm.vue";
import TakipHazirlaDayanakEkleForm from "@/components/eicrapro/TakipHazirlaDayanakEkleForm.vue";
import {MtsXmlParser} from "@/plugins/uyap-plugin/takip_ac/MtsXmlParser";
import {AdliyelerCevap} from "@/plugins/uyap-plugin/uyap/Adliyeler";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {DosyaFormDataInterface} from "@/plugins/uyap-plugin/takip_ac/DosyaFormDataInterface";

@Component({
  components: {
    TakipHazirlaDayanakEkleForm,
    MahiyetSecForm, FormButton, DayanakList, EPosta, TelefonNumarasi, TakipHazirlaEvrakEkleForm, FormDialog
  }
})
export default class MtsTakipHazirlaFormWizard extends Mixins(ObjectInputMixin) {
  @Ref('form') readonly form!: VForm;

  secilenDosyalar: Array<MtsXmlParser> = [];
  activeStep: number = 1;
  onFly = false;
  parentTaskId = null;
  mdb = new MDB();
  yetkiliKurumlar: Array<AvukatYetkiliKurumlarItem> = [];
  takipHazirlaStatus = TakipHazirlaStatus;
  stepperHeaders = ['Takip Bilgileri', 'Takipler Hazırlanıyor', 'İşlemler Sıraya Alındı'];
  dosyalar: Array<MtsXmlParser> = [];
  headers = [
    {text: "#", value: "tempId", width: '20'},
    {text: "Alacaklı", value: "alacaklilar"},
    {text: "Borçlu", value: "borclular"},
    {text: "Takibe Esas Tutar", value: "toplamTakibeEsasMiktar", width: "175"},
    {text: "İşlemler", value: "actions", align: "center"},
    {text: "Durum", value: "status", width: "80", align: "center"}
  ]
  isParsing = false;
  adliyeler: AdliyelerCevap = [];
  ibanTercihiItems = [
    {text: 'Avukat', value: 'Avukat'},
    {text: 'Müvekkil', value: 'Müvekkil'},
    {text: "IBAN'sız", value: 'Yok'}
  ];
  durumAciklamasi = '';
  vekaletCache: Array<{ hash: string, data: FileEntity }> = [];
  async mounted() {
    this.yetkiliKurumlar = await this.$uyap.AvukatYetkiliKurumlar().run({});
  }

  async onIlChange() {
    if (this.localValue.il) {
      this.adliyeler = await this.$uyap.Adliyeler().run({ilKodu: this.localValue.il});
    } else
      this.adliyeler = [];
    this.input();
  }

  async onMtsYetkiliKurumChanged() {
    // TODO: sorulacak
    //kisiKurumId, eposta, telefon mtskurumiletisim tablosu olacak
    try {
      let iletisim = (await this.$http.get('/api/v1/mts-kurum-iletisim/?kisiKurumId=' + this.localValue.alacakliTaraf)).data;
      this.localValue.alacakliTelefon = iletisim.telefon;
      this.localValue.alacakliEposta = iletisim.eposta;
    } finally {
      this.input();
    }
  }

  fileRule(file: any) {
    if (file) {
      let uzanti = file.name.split('.').pop();
      if (["xml", "XML"].indexOf(uzanti) >= 0)
        return true;
      else
        return "Sadece xlm dosya yüklenebilir.";
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  tumDosyalariSec() {
    if (this.secilenDosyalar.length == this.dosyalar.length) {
      this.secilenDosyalar = [];
    } else {
      this.secilenDosyalar = this.dosyalar;
    }
  }

  async nextStep() {
    if (this.activeStep == 1 && this.form.validate()) {
      try {
        this.activeStep++;
        this.isParsing = true;
        let xml = await XmlController.readXmlWithEncoded(this.localValue.xml);
        let parsedXml = await XmlController.mtsParse(xml, this.localValue);
        parsedXml.forEach((dosya: any, index: number) => {
          dosya.status = TaskDurum.SIRADA;
          dosya.tempId = index + 1;
        });
        this.dosyalar = parsedXml;
        this.secilenDosyalar = this.dosyalar;
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isParsing = false;
      }
    }
    this.focusStep(this.activeStep);
  }

  async takipHazirla() {
    this.onFly = true;
    let dosya: any;
    for (dosya of this.secilenDosyalar) {
      if (dosya.status != this.takipHazirlaStatus.tamamlandi_hazir &&
          dosya.status != this.takipHazirlaStatus.tamamlandi_bitti) {
        dosya.status = this.takipHazirlaStatus.isleniyor;
        if (dosya.vekalet) {
          this.durumAciklamasi = '#' + dosya.tempId + ' dosyanın vekaleti kontrol ediliyor';
          let vekaletData = await this.vekaletKontrol(dosya);
          dosya.vekaletObject = vekaletData;
          delete dosya.vekalet;
          this.durumAciklamasi = '#' + dosya.tempId + ' dosya hazır';
        } else {
          this.durumAciklamasi = '#' + dosya.tempId + ' dosyanın vekaleti yok';
        }
        dosya.status = this.takipHazirlaStatus.tamamlandi_hazir;
      }
    }
    await this.createTask();
    this.onFly = false;
  }

  async vekaletKontrol(dosya: any): Promise<FileEntity> {
    let vekaletData: FileEntity;
    let vekalet = dosya.vekalet.data.replace('<!--[CDATA[', '').replace(']]-->', '');
    const Crypto = require('crypto');
    let hash = Crypto.createHash('sha1').update(vekalet).digest().toString('hex');
    let formData = new FormData();
    let vekaletBlob = this.$helper.base64toBlob(vekalet, dosya.vekalet.mimeType);
    let findCache = this.vekaletCache.find(cache => cache.hash == hash);
    if (!findCache) {
      formData.append('vekilList', JSON.stringify(dosya.vekilList));
      formData.append('tarafList', JSON.stringify(dosya.tarafList.filter((taraf: TarafInterface) => taraf.tarafSifati.rolID == 21)));
      formData.append('aciklama', dosya.vekalet.name);
      formData.append('name', dosya.vekalet.name);
      formData.append('file', vekaletBlob);
      formData.append('dokuman_turu_id', '1');
      formData.append('hash', hash);
      formData.append('mime_type', dosya.vekalet.mimeType);
      vekaletData = await this.$http.post('/api/v1/task/vekalet-senkron', formData);
      this.vekaletCache.push({hash: hash, data: vekaletData});
    } else {
      vekaletData = findCache.data;
    }
    return vekaletData;
  }

  // dosya kaydetme hatası düzeltilene kadar kaldırma
  waitThisLong(saniye: number) {
    return new Promise(resolve => setTimeout(resolve, saniye * 1000))
  }

  async createTask() {
    if (this.secilenDosyalar.length == 0){
      this.$toast.warning('Hazırlanacak takipleri seçiniz.');
      return;
    }
    let tasks: Array<ITask> = [];
    let hazirDosyalar = this.secilenDosyalar.filter((dosya:any) => dosya.status == this.takipHazirlaStatus.tamamlandi_hazir);
    for (let dosya of hazirDosyalar) {
      let dayanaklar = [];
      for (const dayanak of dosya.dayanaklar) {
        let formData = new FormData();
        let base64 = dayanak.data.replace('<!--[CDATA[', '').replace(']]-->', '');
        const Crypto = require('crypto');
        let hash = Crypto.createHash('sha1').update(base64).digest().toString('hex');
        let blob = this.$helper.base64toBlob(base64, dayanak.mimeType);
        formData.append('aciklama', dayanak.name);
        formData.append('name', dayanak.name);
        formData.append('file', blob);
        formData.append('hash', hash);
        formData.append('dokuman_turu_id', "9");
        formData.append('mime_type', dayanak.mimeType);
        let dayanakResponse = await this.$http.post('/api/v1/file', formData);
        dayanaklar.push(dayanakResponse.data);
        // dosya kaydetme hatası düzeltilene kadar kaldırma
        await this.waitThisLong(1);
      }
      dosya.dayanaklar = dayanaklar;
      // @ts-ignore
      dosya.xmlName = this.localValue.xml.name;
      let task: Task = new Task();
      task.parent_id = this.parentTaskId;
      task.task_type_id = TaskTuru.mts_takip_hazirla;
      task.data = dosya
      tasks.push(task);
    }
    let response = await this.$store.dispatch('postTasks', tasks);
    if (response)
      this.parentTaskId = response.data.id;
    this.islemlereGit();
    this.activeStep++;
  }

  addVekalet(vekalet: any, dosya: any) {
    dosya.vekalet = vekalet;
  }

  addAllVekalet(vekalet: any) {
    this.dosyalar.forEach((dosya: any) => {
      if (!dosya.vekalet)
        dosya.vekalet = vekalet;
    });
  }

  addAllDayanak(dayanaklar: Array<any>) {
    this.dosyalar.forEach((dosya: any) => {
      dayanaklar.forEach(dayanak => {
        dosya.dayanaklar.push(dayanak);
      });
    });
  }

  previousStep() {
    if (this.activeStep > 1) {
      this.activeStep--;
    }
    this.focusStep(this.activeStep);
  }

  focusStep(step: any) {
    let activeHeader = (this.$refs['step' + step] as Vue[])[0].$el;
    let headers = this.$refs['headers'] as Element;
    let activeHeaderRight = activeHeader.getBoundingClientRect().right;
    let headersLeft = headers.getBoundingClientRect().left;
    let headersWidth = headers.getBoundingClientRect().width;
    let scrollPosition = (activeHeaderRight - headersLeft) - headersWidth;
    if (scrollPosition < 0) {
      scrollPosition = 0;
    }
    headers.scrollLeft += scrollPosition;
  }

  islemlereGit() {
    this.$router.push(ProgramPaths.eicrapro + '/islemler');
  }

  sifirla() {
    this.localValue = new DosyaFormDataInterface();
    this.input();
    this.activeStep = 1;
  }
}

enum TakipHazirlaStatus {
  sirada = 1,
  isleniyor,
  vekalet_yok,
  mukerrer,
  tamamlandi_hazir,
  tamamlandi_bitti
}
